<template>
  <router-link :to="{name:'home'}" class="d-flex align-center">
    <div class="logo flex flex-column justify-center">
      <img :src="logo">
    </div>
  </router-link>
</template>


<script>
export default {
  name: "Logo",
  props: ['white'],
  data() {
    return {}
  },

  methods: {},

  computed: {
    logo() {
      let logo = '/assets/logo';
      if (this.white) {
        logo += '_white';
      }
      if (this.$i18n.locale === 'ar') {
        logo += '_ar';
      }
      return logo + '.svg'
    }
  }
}
</script>
<style lang="sass">@import "style"</style>
