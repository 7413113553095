<template>
  <footer class="footer pt-10 pb-4">
    <div class="container ">
      <div class="row justify-space-between">
        <div class="col-md-2 d-flex flex-column align-center align-md-start">
          <Logo class="mb-3" white="true"/>
          <p>© Name, 2021</p>
          <router-link :to="{name:'PrivacyPolicy'}" target="_blank">{{ $t('pages.privacy_policy') }}</router-link>
<!--          <router-link :to="{name:'TermsAndConditions'}" target="_blank">{{ $t('pages.terms_conditions') }}</router-link>-->
<!--          <payment-logos></payment-logos>-->
        </div>
        <div class="col-md-6 d-none d-md-flex align-center justify-space-between justify-md-center justify-lg-space-between flex-md-column flex-lg-row">
          <router-link flat v-for="item in menuItems" :key="item.id" :to="item.path" class="menu__item  font-weight-medium mb-md-2 mb-lg-0">
            <v-icon left dark>{{ item.icon }}</v-icon>
            {{ $t(item.title) }}
          </router-link>
        </div>
        <div class="col-auto d-flex flex-column align-center align-self-center align-md-start">
          <span class="font-weight-medium mb-3"><v-icon color="white" class="mr-3">mdi-phone-outline</v-icon>56 342 4401</span>
          <span class="font-weight-medium mb-3"><v-icon color="white" class="mr-3">mdi-email-outline</v-icon>email@email.com</span>
          <span class="font-weight-medium "><v-icon color="white" class="mr-3">mdi-map-marker-outline</v-icon>Riyadh - Beer salman<br>-3575-754</span>
        </div>
      </div>
<!--      <div class="mt-2">{{$t('home.copyright')}}</div>-->
    </div>
  </footer>
</template>
<script>
import Logo from "@/components/base/Logo/index";
// import PaymentLogos from "@/components/PaymentLogos";

export default {
  name: "AppFooter",
  components: {
    // PaymentLogos,
    Logo},
  data: () => ({
    sidebar: false,
    dialogLogin: false,
    menuItems: [
      {id: 1, title: 'pages.home', path: '/'},
      {id: 2, title: 'pages.opportunities', path: {name: 'Opportunities'}},
      {id: 3, title: 'pages.menu_item', path: ''},
      {id: 5, title: 'pages.contact_us', path: {name: 'ContactUs'}}
    ],
    locales: [
      'en', 'ar'
    ],
  }),
}
</script>
<style lang="scss">
.footer .logo {
  margin: 0 10px 0 0;
}
.footer * {
  color: white;
}
.footer .menu__item{
  color: white;
  opacity: 0.5;
}
</style>