<template>
  <v-card class="my-5 py-2 px-3">
    <h2 class="font-weight-bold mb-4">Profit</h2>
    <line-chart v-if="gradient" :chartData="datacollection" :options="defaultOptions()"
                style="max-height: 400px;"></line-chart>
    <canvas id="canvas_traffic" class="d-none"></canvas>
  </v-card>
</template>

<script>
import { LineChart } from 'vue-chart-3';

export default {
  components: {
    LineChart
  },
  data() {
    return {
      gradient: null
    }
  },
  mounted() {

    let canvas = document.getElementById('canvas_traffic');
    let ctx = canvas.getContext('2d');
    let gradient = ctx.createLinearGradient(0, 350, 0, 0);
    gradient.addColorStop(0, 'rgba(39,123,192,0)');
    gradient.addColorStop(1, '#277BC0');
    this.gradient = gradient;
  },
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    defaultDatasets() {
      const color = '#277BC0'
      return [
        {
          filled: true,
          backgroundColor: this.gradient,
          borderColor: color,
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: color,
          borderWidth: 2,
          data: [150, 230, 100, 150, 50],
        },
      ];
    },
    labels() {
      return ['JAN', 'FEB', 'MAR', 'APR', 'MAY'];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              callback: function(value, ) {
                return '$' + value;
              }
            },
          }]
        },
        elements: {
          line: {
            borderWidth: 1
          },
          point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4
          }
        }
      }
    }
  },
  computed: {
    datacollection() {
      return {
        labels: this.labels(),
        datasets: this.defaultDatasets(),
      }
    },
  }
}
</script>
