<template>
  <div class="home_page">
    <section class="bg-container home-wrapper d-flex flex-column justify-center">
      <div class="container relative">
        <div class="row">
          <div class="col-md-6 col-xl-4 col-11">
            <h1 class="home__bg__title">{{ $t('home.title') }}</h1>
            <a href="#" class="btn home__bg__btn mt-3 mb-6 btn__dark_blue">{{ $t('home.learn_more') }}</a>
          </div>
          <div class="col-md-6">
            <img src="@/assets/images/bg.png" width="660" height="539" class="w-100" :alt="$t('home.title')">
          </div>
        </div>
      </div>
    </section>
    <investment-opportunities></investment-opportunities>
    <about-section></about-section>
    <why-section></why-section>
    <faq-section></faq-section>
    <contact-section></contact-section>

  </div>
</template>

<script>
import InvestmentOpportunities from "@/view/pages/Home/InvestmentOpportunities";
import AboutSection from "@/view/pages/Home/AboutSection";
import WhySection from "@/view/pages/Home/WhySection";
import FaqSection from "@/view/pages/Home/FaqSection";
import ContactSection from "@/view/pages/Home/ContactSection";
export default {
  name: 'Home',
  components: {ContactSection, FaqSection, WhySection, AboutSection, InvestmentOpportunities},
}
</script>
<style lang="scss">
@import "../../assets/scss/var";


.btn {
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  background: $cBlue;
  color: $cWhite !important;
  font-size: 18px;
  padding: 5px 30px;
  border-radius: 5px;
  font-weight: bold;
  line-height: 24px;
  box-shadow: 0 3px 6px 0 rgba(34, 60, 80, 0.16);

  &__cancel {
    background: $cLight;
    color: $cGray !important;
  }

  &__info {
    background: $cInfo;
  }
  &__dark_blue {
    background: $cBlueDark;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    padding: 11px 57px;
  }
  @media (min-width: 1024px) {
    padding: 11px 73px;
  }

}

.home {
  &__bg__title {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    line-height: 51px;
    font-weight: bold;
    margin: 0 0 60px 0;
    @media (min-width: 768px) {
      font-size: 60px;
      line-height: 90px;
    }
  }
}


</style>
