<template>
  <div>
    <v-toolbar class="rounded-t-lg border-primary" style="border-bottom:2px solid;box-shadow: none">
      <v-list-item-title class="d-flex align-center h2 font-weight-bold">
        Opportunity #{{ id }} Investors
      </v-list-item-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <a href="#" @click.prevent="exportAgreement" class="v-list-item" v-if="closed">
          <v-list-item-title class="d-flex align-center text-body-2">
            {{$t('common.export')}}
          </v-list-item-title>
        </a>
        <a href="#" @click.prevent="submitAll" class="v-list-item">
          <v-list-item-title class="d-flex align-center text-body-2">
            Return All ({{total_to_return | toCurrency}})
          </v-list-item-title>
        </a>
        <router-link :to="{name:'opportunity-list'}" class="v-list-item">
          <v-list-item-title class="d-flex align-center text-body-2">
            {{$t('common.back')}}
          </v-list-item-title>
        </router-link>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
        :options.sync="table.options"
        :headers="headers"
        :items="items"
        :items-per-page="table.pagination.defaultPageSize"
        :loading="table.loading"
        :server-items-length="table.total"
        :single-expand="true"
        :expanded.sync="expanded"
        class="rounded-b-lg"
        show-expand
        :footer-props="{
          itemsPerPageOptions: table.pagination.pageSizeOptions,
        }"
    >
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length" class="py-3">
          <v-data-table
              :headers="sub_headers"
              :items="sub_items"
              :loading="sub_header_loading"
              hide-default-footer
          >
          </v-data-table>
        </td>
      </template>
      <template v-slot:item.return="{ item }">
        <v-text-field type="number"
                      :placeholder="placeItem(item)"
                      :ref="'value_'+item.user_id"
                      append-icon="mdi-arrow-right-bold"
                      @click:append="returnValue(item.user_id)"
                      v-if="!item.returned"

        ></v-text-field>
      </template>
    </v-data-table>

    <v-pagination
        v-model="table.options.page"
        :length="table.pages"
        @input="onPageChange"
    >
    </v-pagination>
  </div>
</template>

<script>
import TablePaginationMixin from "@/mixins/tablePagination"

export default {
  name: "OpportunityInvestors",
  props: ['id'],
  data: () => ({
    items: [],
    expanded: [],
    subitems: [],
    sub_header_loading: false,
    table: {
      options: {
        sortBy: ['amount_total'],
        sortDesc: [true],
      }
    },
    total_to_return: 0,
    closed: false,
  }),

  mixins: [TablePaginationMixin],
  watch: {
    'table.options': {
      handler() {
        this.getList()
      },
      deep: true,
    },
    'expanded': {
      handler() {
        this.getSub()
      },
    },
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$http.get('/admin/opportunities/' + this.id + '/investors', {params: {...this.table.options}}).then(({data}) => {
        this.items = data.data;
        if (data.meta) {
          this.table.pages = data.meta.last_page;
          this.table.total = data.meta.total;
        }
      }).finally(() => {
        this.table.loading = false;
      });
    },
    getSub() {
      if (this.expanded && this.expanded.length > 0) {
        this.sub_header_loading = true;
        this.$http.get('/admin/opportunities/' + this.id + '/investors/' + this.expanded[0].user_id).then(({data}) => {
          this.subitems = data.data;
        }).finally(() => {
          this.sub_header_loading = false;
        });
      }
    },
    exportAgreement(){
      this.$http.get('/admin/opportunities/'+this.id+'/investors/export', {responseType: 'blob'}).then((res) => {
        this.downloadFile(res, 'opportunity_investors_agreement.pdf');
      })
    },
    downloadFile(response, fname) {
      let blob = new Blob([response.data], {type: response.headers["content-type"]});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fname;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onPageChange() {
      this.getList();
    },
    submitAll(){
      this.$http.post('/admin/opportunities/' + this.id + '/investors/')
          .then(() => {
            this.$noty.success(this.$t('wallet.return_started'));
            this.getList();
          })
          .catch(({response}) => {
            if (response.data.message ) {
              this.$noty.error(response.data.message);
            }
          })
    },
    returnValue(user_id) {
      let ref = this.$refs['value_' + user_id];
      if (ref) {
        let val = ref.internalValue;
        this.$http.put('/admin/opportunities/' + this.id + '/investors/' + user_id, {val: val})
            .then(() => {
              ref.reset()
              ref.errorBucket = [];
              this.$noty.success(this.$t('wallet.returned'));
              this.getList();
            })
            .catch(({response}) => {
              if (response.data.errors && response.data.errors.val) {
                ref.errorBucket = response.data.errors.val
              }
            })
      }
    },
    placeItem(item){
      return String((Number(item.roi / 100 * item.amount_total) + Number(item.amount_total)).toFixed(0))
    },
    getReturnAllAmount() {
      this.$http.get('/admin/opportunities/' + this.id + '/investor_amount').then(({data}) => {
        this.total_to_return = Number(data.total);
        this.closed = data.closed;
      })
    },
  },

  mounted() {
    // this.getList();
    this.getReturnAllAmount();
  },

  computed: {
    headers() {
      return [
        // {text: 'Opportunity ID', value: 'opportunity_id', width: "100px"},
        {text: 'Investor ID', value: 'user_id', width: "85px", sortable: false},
        {text: 'Investor Name', value: 'user', width: "85px", sortable: false},
        {text: 'Phone', value: 'phone', width: "85px", sortable: false},
        {text: 'Total Shares', value: 'shares_total', width: "85px"},
        {text: 'Total Amount', value: 'amount_total', width: "85px"},
        {text: 'ROI', value: 'roi', width: "85px", sortable: false},
        {text: 'Return', value: 'return', width: "85px", sortable: false},
      ]
    },
    sub_headers() {
      return [
        // {text: 'Opportunity ID', value: 'opportunity_id', width: "100px"},
        {text: 'Shares', value: 'shares', width: "85px"},
        {text: 'Amount', value: 'amount', width: "85px"},
        {text: 'Date', value: 'date', width: "85px"},
        {text: 'Transaction', value: 'transaction_ref', width: "85px"},
      ]
    },
    sub_items() {
      if (this.sub_header_loading) {
        return [];
      }
      return this.subitems
    }
  }
}

</script>
<style lang="sass" scoped>
@import "@/assets/scss/var"

.border-primary
  border-color: $cBlue !important

.open
  color: $cBlue !important

.closed
  color: $cRed !important

</style>
