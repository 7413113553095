<template>
  <v-app-bar
      height="80px"
      class="header bg"
      :color="'white'"
      dense
      fixed
      app
      clipped-left
  >
    <div class="container flex">

      <Logo/>
      <v-app-bar-nav-icon @click.stop="$emit('displayMenu')" class="hidden-md-and-up"></v-app-bar-nav-icon>

      <v-toolbar-items class="hidden-sm-and-down menu menu__header ">
        <router-link flat v-for="item in menuItems" :key="item.id" :to="item.path" class="menu__item menu__header__item">
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ $t(item.title) }}
        </router-link>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <social-links wrapper_class=" header_socials rounded" class="d-none d-lg-flex"></social-links>
      <v-toolbar-items class="menu ">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="menu__item menu__item__dropdown px-1">
              <v-list-item-title class="text-uppercase">{{ $i18n.locale }}</v-list-item-title>
              <v-list-item-action class="ml-1 ml-md-2">
                <font-awesome-icon :icon="['fa', 'caret-down']"/>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item v-for="locale in locales" :key="'locale-'+locale" @click="switchLocale(locale)">
              <v-list-item-title class="text-uppercase">{{ locale }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="menu__item menu__item__login menu__item--active" v-if="!$auth.user()">
              <v-list-item-title>{{ $t('auth.login') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-on="on" class="menu__item menu__item__login menu__item--active pl-1" v-else>
              <v-list-item-title class="d-none d-md-inline">{{ $auth.user().first_name }} {{
                  $auth.user().last_name
                }}
              </v-list-item-title>
              <v-list-item-action class="ml-1">
                <font-awesome-layers class="login__icon" v-if="!$auth.user().logo">
                  <font-awesome-icon class="login__icon__circle" :icon="['fa', 'circle']"/>
                  <font-awesome-icon class="login__icon__user" :icon="['far', 'user']" transform="shrink-10"/>
                </font-awesome-layers>
                <v-img max-width="60px" class="rounded-circle" v-else :src="$auth.user().logo"></v-img>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list>
            <template v-if="$auth.user()">
              <v-list-item :to="{name:'Dashboard'}">
                <v-list-item-title>{{ $t('pages.dashboard') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title>{{$t('auth.logout')}}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item @click="login()">
                <v-list-item-title>{{$t('auth.login')}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="signUp()">
                <v-list-item-title>{{$t('auth.sign_up')}}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </div>
  </v-app-bar>
</template>

<script>
import Logo from '@/components/base/Logo'
import SocialLinks from "@/view/pages/Home/SocialLinks";

// TODO: after click on login call vuex open sign in form
export default {
  data: () => ({
    sidebar: false,
    drawer: false,
    dialogLogin: false,
    menuItems: [
      {id: 1, title: 'pages.home', path: '/'},
      {id: 2, title: 'pages.opportunities', path: {name: 'Opportunities'}},
      {id: 3, title: 'pages.menu_item', path: ''},
      {id: 5, title: 'pages.contact_us', path: {name: 'ContactUs'}}
    ],
    locales: [
      'en', 'ar'
    ],
  }),

  components: {
    SocialLinks,
    Logo
  },

  methods: {
    openLoginModalForm() {
      this.dialogLogin = true;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$vuetify.rtl = locale === 'ar';
        this.$i18n.locale = locale;
      }
    },

    login() {
      this.$emit('login')
    },
    logout() {
      console.log('logout')
      this.$auth.logout({});
    },
    signUp() {
      this.$emit('signUp')
    },
  },

  created() {

  }
}

</script>
<style lang="sass">@import "style"</style>
