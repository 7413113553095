<template>
  <div id="app" :class="'layout-'+layout">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Dashboard from '@/view/layouts/Dashboard'
import Home from '@/view/layouts/Home'
const components = { Dashboard, Home }

export default {
  components,
  computed: {
    layout () {
      const key = this.$get(this.$route, 'meta.layout')
      console.log(key)
      return components[key] ? key : 'Home'
    }
  }
}
</script>
