export default {

  hello: 'Hello World',

  auth: {
    new_password: 'كلمة مرور جديدة',
    confirm_password: 'تأكيد كلمة المرور',
    dont_have_account_question: 'لايوجد لديك حساب',
    sign_up: 'تسجيل مستخدم',
    enter_code: 'ادخل الرمز المرسل لجوالك',
    email_verified: 'تم التحقق من الايميل',
    email: 'الايميل',
    login: 'تسجيل دخول',
    resend_verification: 'إعادة ارسال ',
    mail_send: 'تم ارسال الايميل',
    forgot_password: 'نسيت كلمة المرور',
    sent_otp: 'تم ارسال رمز للتحقق لجوالك',
    enter_code_below: 'ادخل الرمز ',
    reset_password: 'إعادة تعيين كلمة المرور',
    already_have_question: 'يوجد لديك حساب في النظام',
    verify_your_email: 'الرجاء الضغط على تحقق للايميل المرسل لك',
    verify_your_email_desc: 'تم ارسال لبريدك الالكتروني الرجاء الضغط على كلمة تحقق لتفعيل حسابك',
    logout: 'خروج',
    mail_verified: 'تم التحقق من ايميلك'

  },

  pages: {
    home: 'الصفحة الرئيسية',
    dashboard: 'المؤشرات',
    opportunities: 'قائمة الفرص المتاحة',
    profile: 'ملف المستخدم',
    contact_us: 'تواصل معنا ',
    menu_item: 'عن المنصة',
    add_funds: 'إضافة مبلغ مالي',
    wallet: 'المحفظة',
    transactions: 'العمليات',
    company_settings: 'اعدادات الشركة',
    users: 'المستخدمين',
    privacy_policy: 'الخصوصية',
    terms_conditions: 'الشروط والاحكام',

  },

  home: {
    title: 'ابقى على اطلاع على افضل الفرص الاستثمارية',
    learn_more: 'لمعرفة المزيد',
    copyright: "نماء للتقنية المالية رقم سجل تجاري 1000101010 3575 -754 شارع بير سلمان",
    investment_opportunities: 'الفرص الاستثمارية المتاحة',
    investment_opportunities_text: 'من خلال منصتنا بامكانك الاطلاع على ابرز الفرص الاستثمارية وبياناتها المالية والقانونية علما بان مبلغ الاستثمار يبدا 1000   ريال في الفرصة  الواحدة',
    see_all: 'الاطلاع على جميع الفرص',
    what_is_emtalk: 'ماهي موارد ؟',
    what_is_emtalks: [' موارد هي منصة مملوكة لشركة نماء للتقنية المالية و تعمل على إعادة اتمتة عمليات الاستثمار في الملكية الخاصة ، وتهدف إلى تحقيق عوائد مناسبة للمستثمرين من خلال الاستثمار في الفرص بشكل تقني.بحيث يتم طرح الفرص على المنصة، وعرض كافة المعلومات التي توضح حالة الفرصة والمعلومات التي تحقق نفي الجهالة، ثم يتم الاستثمار بشكل إلكتروني حيث يصبح للمستثمر ملكية محددة في الفرصة المطروحة.'],
    why_marks: [
      {
        title: 'الموثوقية',
        text: 'بالتعاون مع شركائنا في القطاع المالي نحرص على اختيار افضل الفرص لضمان عوائد مجزية لعملائنا',
        icons: ['shield', 'check white-icon']
      },
      {
        title: 'الامان',
        text: 'الاستثمار في قطاعات مختلفة  يعتبر احد افضل المنهجيات للمحافظة على رأس المال وتنميته بشكل آمن',
        icons: ['lock-alt']
      },
      {
        title: 'توظيف التقنية',
        text: 'نحن نستخدم افضل المنهجيات والممارسات التقنية',
        icons: ['laptop']
      },
      {
        title: 'معلومات عن الفرص الاستثمارية',
        text: 'معلومات موثوقة عن الفرص الاستثمارية',
        icons: ['search-location']
      },
    ],
    faq: 'الأسئلة الشائعة',
    faq_items: [
      {
        title: 'كيف الاستثمار في منصة موارد',
        text: 'بخطوات بسيطة بامكانك شحن رصيدك في المنصة واختيار الفرصة الاستثمارية والاستثمار فيها'
      },
      {
        title: 'هل الأرباح مضمونة',
        text: 'لايوجد ضمان على الأرباح  وقد يترتب على الاستثمار ربح او خسارة'
      },
    ],

    contact_us: 'تواصل معنا',
    sign_up: 'تسجيل جديد',
    why_we: 'لماذا نحن ؟'


  },

  common: {
    back: 'رجوع',
    search: 'بحث',
    save: 'حفظ',
    saved: 'تم الحفظ',
    cancel: 'إلغاء',
    send: 'ارسل',
    resend: 'إعادة ارسال',
    submit: 'ارسل',
    proceed: 'إكمال',
    update: 'تحديث',
    export: 'اصدار الملف',

  },

  opportunities: {
    target: 'اجمالي مبلغ الطرح',
    raised_amount: 'اجمالي المبالغ المحصلة',
    equitiy_offered: 'نسبة الشراكة المطروحة',
    current_investors: 'المستثمر الحالي',
    minimum_target: 'الحد الادني للاستثمار',
    maximum_target: 'الحد الاعلى للاستثمار',
    minimum_equitiy_offered: 'أقل عدد من الحصص او الاسهم',
    maximum_equitiy_offered: 'أقصى عدد من الحصص او الاسهم',
    val_pre_money: 'التقيم قبل جولة الاستثمار',
    val_post_money: 'Valuation post-money',
    number_of_shares: 'عدد الأسهم المطروحة',
    invest: 'المستثمر',
    owners: 'الملاك ',
    open_period: 'فترة الطرح',
    share_amount: 'قيمة السهم',
    investment_ceiling: 'الحد الأعلى للاستثمار في ',
    roi: 'نسبة العائد على الاستثمار المتوقع',
    type: 'النوع',
    purpose: 'الهدف ',
    area: 'المساحة',
    designer: 'المصمم',
    developer: 'المطور',
    project_plan: 'خطة عمل المشروع',
    stakeholder: 'ملاك المشروع ',
    location: 'موقع المشروع',
    media: 'الملفات ',
    all: 'All',
    owned: 'الفرص الخاصة بي',
    invested: 'الفرص التي استثمرت فيها',
    investors_number: 'المستثمرين',
    status: 'الحالة',
    opening_date: 'موعد الطرح',
    close_date: 'موعد الاغلاق',
    id: 'الهوية',
    title: 'اسم الفرصة',
    choose_location: 'حدد العنوان',
    type_address: 'ادخل العنوان بشكل يدوي او اختر من الخريطة',
    period_date: 'فترة الطرح',
    real_estate_info: 'بيانات الفرصة',
    search_address: 'البحث عن عنوان',
    description: 'الوصف',
    total_shares: 'اجمالي عدد الاسهم',
    target_shares: 'اجمالي عدد الأسهم المطروحة',
    evaluation: 'التقييم',
    equitity_offered: 'نسبة الشراكة المطروحة',
    file_validation: 'يجب ان لايتعدى حجم الملف التالي',
    recommend_investment: ' تحذير نحن ننصحك بعدم تغيير الحصص لانه تم الاستثمار في الفرصة ',
    ask_update: 'هل ترغب فعليا في تحديث الفرصة ؟'

  },

  opportunities_invest: {
    summary: 'تفاصيل العمليات ',
    request_number: 'رقم الطلب ',
    invested_amount: 'قيمة الاستثمار',
    transaction_fee: 'رسوم العملية',
    amount_to_pay: 'المبلغ الذي سيتم دفعه ',
    date: 'اليوم',
    time: 'الوقت ',
    use_wallet_balance: 'استخدام المحفظة',
    amount: 'المبلغ',
    pay: 'دفع',
    retry: 'المحاولة مرة اخرى',
    payment_success: 'تم الدفع بنجاح',
    payment_fail: 'تم فشل عملية الدفع',

  },

  wallet: {
    amount_to_add: "المبلغ الذي سيتم أضافته",
    current_balance: 'الرصيد',
    view_transaction_history: 'عرض العميات ',
    returned: 'إعادة المبالغ',
    return_started: 'تم البدء بإعادة المبلغ',
    pay: 'دفع',
    add_funds: 'إضافة مبلغ',
    transaction_id: 'رقم العملية',

  },

  profile: {
    info: 'حقول لاتظهر للمستثمرين',
    title: 'ملف المستثمر',
    first_name: 'الاسم الأول',
    last_name: 'الاسم الاخير',
    national_id: 'الهوية الوطنية',
    phone: 'رقم الجوال',
    email: 'الايميل',
    nationality: 'الجنسية',
    income: 'مبلغ الدخل الشهري ',
    birthdate: 'تاريخ الميلاد',
    address_info: 'بيانات العنوان الوطني',
    city: 'المدينة',
    district: 'الحي',
    street: 'الشارع',
    po_box: 'العنوان البريدي',
    postal_code: 'الرمز البريدي',
    bank_info: 'معلومات البنك',
    holder_name: 'اسم مالك الحساب',
    iban: 'رقم الايبان',
    user_acc_info: 'رقم الايبان',
    attach_media: 'ارفاق ملف',
    password: 'الرقم السري',
    email_need_verify: 'تم تغيير الايميل الرجاء تفعيل ايميلك',
    download_investment_agreement: 'المحفظة الاستثمارية'

  },

  users: {
    id: 'ID',
    first_name: 'الاسم الأول',
    last_name: 'الاسم الاخير',
    email: 'الايميل',
    phone: 'رقم الجوال #',

  },

  dashboard: {
    wallet_funds: 'رصيد المحفظة',
    raise_funds: 'المبالغ المستثمرة',
    expected_returns: 'العائد المتوقع',
    current_risk_tolerance: 'نسبة المخاطرة',
    portfolio_percentage: 'المحفظة الاستثمارية',
    investment_details: 'تفاصيل الاستثمارات ',

  },

  contact_us: {
    contact: 'تواصل',
    name: 'الاسم',
    subject: 'الموضوغ',
    email: 'الايميل',
    phone: 'رقم الجوال',
    message: 'الرسالة',
    send: 'ارسل',
    success_message: 'لقد تم ارسال الرسالة سيتم التواصل معك قريبا'

  }

} 

 