<template>
  <v-container>
    <div class="d-flex mb-3 justify-space-between">
      <h1 class="font-weight-bold">{{ $t('pages.dashboard') }}</h1>
      <div class="d-flex"><span class="text--blue mx-4">During all time</span>
        <div class="text--disabled">
          <v-icon>mdi-calendar</v-icon>
          Select Time
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="6" lg="3">
        <v-card>
          <v-card-title class="font-weight-bold">
            {{ $t('dashboard.wallet_funds') }}
          </v-card-title>
          <v-card-text>
            <p class="text--blue font-weight-black mb-0 text-h45">{{ widgets.wallet_amount | toCurrency(false) }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <v-card>
          <v-card-title class="font-weight-bold">
            {{ $t('dashboard.raise_funds') }}
          </v-card-title>
          <v-card-text>
            <p class="text--blue font-weight-black mb-0 text-h45">{{ widgets.raised_funds | toCurrency(false) }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <v-card>
          <v-card-title class="font-weight-bold">
            {{ $t('dashboard.expected_returns') }}
          </v-card-title>
          <v-card-text>
            <p class="text--blue font-weight-black mb-0 text-h45">{{ widgets.expected | toCurrency(false) }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <v-card>
          <v-card-title class="font-weight-bold">
            XXX
          </v-card-title>
          <v-card-text>
            <p class="text--blue font-weight-black mb-0 text-h45">XXX</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <profit-chart></profit-chart>
    <investments-chart ref="investments_chart"></investments-chart>
    <v-row>
      <!--      <v-col cols="12" md="6">-->
      <!--        <current-risk-tolerance></current-risk-tolerance>-->
      <!--      </v-col>-->
      <v-col cols="12" md="6">
        <portfolio-percentage ref="portfolio_percentage"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfitChart from "@/view/pages/Dashboard/UserComponents/ProfitChart";
// import CurrentRiskTolerance from "@/view/pages/Dashboard/UserComponents/CurrentRiskTolerance";
import PortfolioPercentage from "@/view/pages/Dashboard/UserComponents/PortfolioPercentage";
import InvestmentsChart from "@/view/pages/Dashboard/UserComponents/InvestmentsChart";

export default {
  name: "UserDashboard",
  components: {
    InvestmentsChart,
    PortfolioPercentage,
    // CurrentRiskTolerance,
    ProfitChart
  },
  data() {
    return {
      widgets: {
        wallet_amount: 0,
        raised_funds: 0,
        expected: 0,
      },
      api_investments: {
        total: 0,
        results: []
      }
    }
  },
  mounted() {
    this.$http.get('/dashboard/widgets').then(({data}) => {
      this.widgets = data;
    });
    this.getApiDataInvestments()
  },
  methods: {
    getApiDataInvestments() {
      this.$http.get('/dashboard/portfolio').then(({data}) => {
        this.api_investments = data;
        this.$refs.portfolio_percentage.load(data);
        this.$refs.investments_chart.load(data);
      })
    }
  }
}
</script>

<style scoped>
.text-h45 {
  font-size: 1.7rem;
}
</style>