<template>
  <div :class="wrapper_class">
    <div class="d-flex" >
      <a href="https://www.instagram.com/" target="_blank" class="a--primary mr-5"><i class="fab fa-instagram"></i></a>
      <a href="https://www.facebook.com/" target="_blank" class="a--primary mr-5"><i class="fab fa-facebook-square"></i></a>
      <a href="https://www.linkedin.com/" target="_blank" class="a--primary mr-5"><i class="fab fa-linkedin"></i></a>
      <a href="https://www.youtube.com/" target="_blank" class="a--primary mr-5"><i class="fab fa-youtube"></i></a>
    </div>
  </div>
</template>

<script>
export default {
name: "SocialLinks",
  props: ['wrapper_class']
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/var";
.a--primary {
  color: $cBlue !important;
  font-size: 24px
}
.rounded .a--primary{
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  background:white;
}



</style>