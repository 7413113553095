import { render, staticRenderFns } from "./OpportunityInvestors.vue?vue&type=template&id=2597b645&scoped=true&"
import script from "./OpportunityInvestors.vue?vue&type=script&lang=js&"
export * from "./OpportunityInvestors.vue?vue&type=script&lang=js&"
import style0 from "./OpportunityInvestors.vue?vue&type=style&index=0&id=2597b645&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2597b645",
  null
  
)

export default component.exports