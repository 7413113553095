<template>
  <v-container>
    <div class="opportunity-card" v-if="opportunity">
      <div class="opportunity-media">
        <div class="opportunity-target">
          <h2 class="font-weight-bold">{{ opportunity.title }}</h2>
        </div>
        <img v-if="opportunity.media.length > 0" :src="opportunity.media[0].url" :alt="opportunity.media[0].title">
      </div>
      <v-row>
        <v-col md="7" cols="12" class=" border-right">
          <div class="opportunity-details">
            <div class="raise mb-4">
              <div class="d-flex mb-2 justify-space-between">
                <span class="text-caption c-gray">0%</span>
                <span class="text-caption c-gray">100%</span>
              </div>
              <v-progress-linear
                  color="primary lighten-1"
                  style="height: 15px"
                  rounded
                  :value="Math.floor(opportunity.raised_amount / opportunity.target * 100)"
              ></v-progress-linear>
            </div>
            <v-row>
              <v-col lg="12" md="12" cols="12">
                <label class="c-gray d-block text-uppercase">{{ $t('opportunities.raised_amount') }}</label>
                <span>{{ opportunity.raised_amount | toCurrency }}</span>
              </v-col>
              <v-col lg="6" md="6" cols="12">
                <label class="c-gray d-block text-uppercase">{{ $t('opportunities.equitiy_offered') }}</label>
                <span>{{ opportunity.equitity_offered }}%</span>
              </v-col>
              <v-col lg="6" md="6" cols="12">
                <label class="c-gray d-block text-uppercase">{{ $t('opportunities.current_investors') }}</label>
                <span>{{ opportunity.investor_count }}</span>
              </v-col>
            </v-row>
            <div v-html="opportunity.details.description" class="my-4"></div>
            <v-row>
              <v-col lg="12" md="12" v-if="opportunity.media && opportunity.media.length > 0">
                <h4>{{ $t('opportunities.media') }}</h4>
                <v-row>
                  <v-col cols="3" v-for="media in opportunity.media" :key="media.id">
                    <div class="media-preview">
                      <a :href="media.url" target="_blank" :title="media.title" class="link" @click.prevent="openMedia(media)">
                        <v-img
                            v-if="media.type === 'image'"
                            contain
                            :src="media.type === 'image'?  media.url : '/assets/images/'+media.type+'.png'"
                        ></v-img>
                        <v-icon v-if="media.type === 'video'" class="typ-ico">mdi-play-circle</v-icon>
                        <v-icon v-if="media.type === 'doc'" class="typ-ico">mdi-file-document</v-icon>
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="12" md="12" class="" v-if="opportunity.details.latitude && opportunity.details.longitude">
                <h4>{{ $t('opportunities.location') }}</h4>
                <GmapMap
                    :center="mapCenter"
                    :zoom="7"
                    map-type-id="terrain"
                    style="width: 100%; height:400px"
                    ref="g_map"
                >
                  <GmapMarker
                      :position="mapCenter"
                      :clickable="true"
                      :draggable="true"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col md="5" cols="12">
          <opportunity-details :opportunity="opportunity">
          <v-text-field
              outlined
              v-model.number="shares"
              type="number"
              min="1"
              :label="$t('opportunities.number_of_shares')"
              :error="hasError('shares')"
              :error-messages="getErrors('shares')"
              :disabled="opportunity.status.id === 2"
          >
          </v-text-field>
          <v-btn
              color="primary"
              class="px-15"
              :disabled="!canInvest()"
              @click.prevent="invest"
          >{{ $t('opportunities.invest') }}
          </v-btn>
        </opportunity-details>
        </v-col>
      </v-row>
    </div>
    <media-modal ref="media_modal"></media-modal>
  </v-container>
</template>

<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import MediaModal from "@/view/pages/Opportunities/MediaModal";
import OpportunityDetails from "@/view/pages/Opportunities/OpportunityDetails";

export default {
  name: "OpportunityView",
  components: {OpportunityDetails, MediaModal},
  props: ['id'],
  mixins: [hasApiValidation],
  data() {
    return {
      opportunity: null,
      loading: true,
      shares: null,
      center: {lat: 10, lng: 10},
      api_key: process.env.VUE_APP_GOOGLE_MAP_KEY
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    openMedia(media){
      this.$refs.media_modal.open(media);
    },
    getData() {
      this.loading = true;
      this.$http.get('/opportunities/' + this.id).then(({data}) => {
        this.opportunity = data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    canInvest() {
      return this.shares && this.$auth.user() && this.$auth.user().role !== 'ADMIN' && this.opportunity.status.id !== 2;
    },
    invest() {
      this.$http.put('/opportunities/' + this.id + '/share', {shares: this.shares}).then(({data}) => {
        this.$router.push({name: 'OpportunityInvest', params: {id: data.data.request_id}})
      }).catch(({response}) => {
        this.setErrors(response.data.errors);
      })
    }
  },
  computed: {
    mapCenter() {
      return {
        lat: Number(this.opportunity.details.latitude),
        lng: Number(this.opportunity.details.longitude),
      }
    },
  }
}
</script>

<style scoped lang="scss">

.opportunity-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
}

.opportunity-media {
  width: 100%;
  height: 120px;
  position: relative;
  background: var(--cBlue);
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opportunity-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 35px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
  border-bottom-right-radius: 10px;
}

.border-right {
  border-right: 1px solid gray;
}

.opportunity-details {
  padding: 20px;

  label {
    font-size: 0.8rem;
  }

  .v-btn {
    height: 40px !important;
    text-transform: none !important;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    background: #88dec7 !important;
    color: white !important;
    text-transform: none !important;
  }
}
</style>