<template>
  <v-card class="my-5 py-2 px-3">
    <h2 class="font-weight-bold mb-4">{{$t('dashboard.investment_details')}}</h2>
    <div style="height: 400px; overflow-y: scroll;position:relative;" v-if="loaded">
      <bar-chart :chartData="datacollection" :options="defaultOptions()"
                 style="min-height: 400px;" id="canv_container"></bar-chart>
    </div>
    <canvas id="canvas_traffic" class="d-none"></canvas>
  </v-card>
</template>

<script>
import {BarChart} from 'vue-chart-3';

export default {
  components: {
    BarChart
  },
  data() {
    return {
      investments: [],
      loaded: false,
    }
  },
  mounted() {
  },
  methods: {
    load(data) {
      this.investments = data.results;
      this.loaded = !!data
      this.calcHeight(this.investments.length)
    },
    calcHeight(numOfBars) {
      let maxHeightOfChart = 400;
      let minHeight = 55; //setting the min height of the bar + margin between
      let chartHeight = minHeight * numOfBars > maxHeightOfChart ? minHeight * numOfBars : maxHeightOfChart;
      document.getElementById("canv_container").style.height = chartHeight.toString() + "px";
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    mapped_data() {
      return this.sort_inv.map(item => Number(item.shares));
    },
    labels() {
      return this.sort_inv.map(item => Number(item.value));
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        indexAxis: 'y',
        skipNull: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              labelPointStyle: function () {
                return {
                  pointStyle: 'triangle',
                  rotation: 0
                };
              },
              afterBody: (context) => {
                return 'Share Amount:' + this.sort_inv[context[0].dataIndex].share_amount;
              },
              footer: (context) => {
                return 'Total:' + this.sort_inv[context[0].dataIndex].value;
              },
              title: (context) => {
                return this.sort_inv[context[0].dataIndex].title;
              },
              label: (context) => {
                return 'Shares:' + this.sort_inv[context.dataIndex].shares;
              }
            },
          }
        },
        elements: {
          bar: {}
        },
        scales: {
          y: {
            grid: {
              offset: false
            }
          }
        }
      }
    }
  },
  computed: {
    sort_inv() {
      return this.investments.slice(0).sort((a, b) => {
        return (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0);
      });
    },
    datacollection() {
      return {
        options: this.defaultOptions(),
        labels: this.labels(),
        datasets: [{
          indexAxis: 'y',
          barPercentage: 0.5,
          barThickness: 40,
          maxBarThickness: 40,
          data: this.mapped_data(),
          backgroundColor: [
            'rgba(39,123,192,0.3)',
          ],
          borderColor: [
            '#277BC0',
          ],
          borderWidth: 1
        }],
      }
    },
  }
}
</script>
