<template>
  <router-link :to="{name: route_name, params: {id:item.id}}" class="card opportunity-list-card">
    <div class="opportunity-list-media">
      <div class="opportunity-list-target">
        <span>{{ $t('opportunities.target') }}</span><br>
        <span class="font-weight-bold"> {{ item.target | toCurrency }}</span>
      </div>
      <img v-if="item.media && item.media.length > 0" :src="item.media[0].url" :alt="item.media[0].title">
    </div>
    <div class="opportunity-list-details">
      <h2 class="font-weight-bold">{{ item.title }}</h2>
      <div class="raise mb-4">
        <div class="d-flex mb-2 justify-space-between">
          <span class="text-caption c-gray">0%</span>
          <span class="text-caption c-gray">100%</span>
        </div>
        <v-progress-linear
            color="primary lighten-1"
            style="height: 15px"
            rounded
            :value="Math.floor(item.raised_amount / item.target * 100)"
        ></v-progress-linear>
      </div>
      <v-row>
        <v-col cols="6">
          <label class="c-gray d-block">{{ $t('opportunities.raised_amount') }}</label>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                    <span
                        v-on="on"
                        class="overflow-hidden  d-block"
                        style="white-space: nowrap;text-overflow: ellipsis;"
                    >{{ item.raised_amount | toCurrency }}</span>
            </template>
            <span>{{ item.raised_amount | toCurrency }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6">
          <label class="c-gray d-block">{{ $t('opportunities.equitiy_offered') }}</label>
          <span>{{ item.equitity_offered }}%</span>
        </v-col>
        <v-col cols="6" class="pt-1 mb-3">
          <label class="c-gray d-block">{{ $t('opportunities.current_investors') }}</label>
          <span>{{ item.investor_count }}</span>
        </v-col>
        <v-col cols="6" class="pt-1 mb-3">
          <label class="c-gray d-block">{{ item.status ? item.status.name : '' }}</label>
        </v-col>
      </v-row>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['item', 'route_name'],
  name: "OpportunityListItem"
}
</script>

<style scoped lang="scss">

.card.opportunity-list-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
  flex-direction: column;

@media screen and (min-width: 768px){
  flex-direction: row;
}
}

.opportunity-list-media {
  position: relative;
  background: var(--cBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  @media screen and (min-width: 768px) {
    width: 48%;
    flex-direction: row;
    height: unset;
  }
}

.opportunity-list-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 15px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
  white-space: nowrap;
}

.opportunity-list-details {
  padding: 5px 10px;
}

.opportunity-list-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>