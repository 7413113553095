<template>
  <div>
    <template v-if="admin()">
      <admin-opportunity-list></admin-opportunity-list>
    </template>
    <template v-else>
      <v-toolbar class="rounded-t-lg border-primary" style="border-bottom:2px solid;box-shadow: none" >
        <v-toolbar-items>
          <v-list-item>
            <v-list-item-title class="pointer font-weight-bold  text--lighten-1"
                               :class="{'primary--text': tab === 'all', 'grey--text': tab !== 'all'}"
                               @click="changeTab('all')"
            >{{ $t('opportunities.all') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="pointer font-weight-bold  text--lighten-1"
                               :class="{'primary--text': tab === 'invested', 'grey--text': tab !== 'invested'}"
                               @click="changeTab('invested')"
            >{{ $t('opportunities.invested') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="pointer font-weight-bold  text--lighten-1"
                               :class="{'primary--text': tab === 'owned', 'grey--text': tab !== 'owned'}"
                               @click="changeTab('owned')"
            >{{ $t('opportunities.owned') }}
            </v-list-item-title>
          </v-list-item>
        </v-toolbar-items>
      </v-toolbar>
      <component :is="view"></component>
    </template>
  </div>
</template>

<script>
import AdminOpportunityList from '@/modules/opportunities/OpportunityList'
import AllOpportunity from '@/view/pages/Investor/Opportunities/List'
import OwnedOpportunities from '@/view/pages/Investor/Opportunities/OwnedOpportunities'
import InvestedOpportunities from '@/view/pages/Investor/Opportunities/InvestedOpportunities'

export default {
  name: "AppOpportunities",
  components: {
    AdminOpportunityList, AllOpportunity, OwnedOpportunities, InvestedOpportunities
  },
  data() {
    return {
      tab: 'all',
    }
  },
  methods: {
    admin() {
      return this.$auth.user().role === 'ADMIN';
    },
    changeTab(tab) {
      return this.tab = tab;
    },
  },
  computed: {
    view() {
      let view = 'AllOpportunity';
      switch (this.tab){
        case "all":
          view = 'AllOpportunity';
          break;
        case "invested":
          view = 'InvestedOpportunities';
          break;
        case "owned":
          view = 'OwnedOpportunities';
          break;
        default:
          break;
      }
      return view
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/var";
.border-primary{
  border-color: $cBlue !important
}
</style>