<template>
  <v-container>
    <h2 class="font-weight-bold mb-4" v-if="success">
      {{ $t('opportunities_invest.payment_success') }}
    </h2>
    <h2 class="font-weight-bold mb-4" v-else>
      {{ $t('opportunities_invest.payment_fail', {reason: message}) }}
    </h2>
    <div class="opportunity-card" v-if="invest">
      <v-card>
        <v-card-text>
          <div class="d-flex align-center flex-wrap ">
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.request_number') }}</label>
              <span class="black--text font-weight-bold">{{ invest.id }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.transaction_fee') }}</label>
              <span class="black--text font-weight-bold ">{{ 0 | toCurrency }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.amount') }}</label>
              <span class="black--text font-weight-bold"> {{ invest.amount | toCurrency }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.date') }}</label>
              <span class="black--text font-weight-bold">{{ invest.created_date }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.time') }}</label>
              <span class="black--text font-weight-bold">{{ invest.created_time }}</span>
            </div>
            <div class="mr-5" v-if="invest.transaction_id">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.transaction_id') }}</label>
              <span class="black--text font-weight-bold">{{ invest.transaction_id }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="mt-5" v-if="invest">
      <router-link :to="{name:'ViewOpportunity', params: {id: invest.opportunity_id}}" class="btn btn__cancel mr-4" v-if="invest && invest.opportunity_id">
        {{ $t('common.back') }}
      </router-link>
      <router-link :to="{name:'Wallet'}" class="btn btn__cancel mr-4" v-else>
        {{ $t('common.back') }}
      </router-link>
      <router-link :to="{name:'Wallet'}" class="btn" v-if="!success && !invest.opportunity_id">
        {{ $t('opportunities_invest.retry') }}
      </router-link>
      <router-link :to="{name:'OpportunityInvest', params: {id: invest.id}}" class="btn" v-if="!success && invest.opportunity_id">
        {{ $t('opportunities_invest.retry') }}
      </router-link>
    </div>
  </v-container>
</template>

<script>


export default {
  name: "OpportunityInvestResult",
  components: {},
  props: ['id'],
  data() {
    return {
      loading: true,
      invest: null,
      card: {
        number: '',
        expire_at: '',
        cardholder: '',
        cvc: '',
      },
      message: '',
      success: true,
    }
  },
  mounted() {
    this.getData();
    this.message = this.$route.query.message
    this.success = this.$route.query.success === 'true';
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http.get('/invests/' + this.id + '/result').then(({data}) => {
        this.invest = data.data;
      }).catch(({response}) => {
        if (response && response.data && response.data.message) {
          this.$noty.error(response.data.message);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {}
}
</script>

<style scoped lang="scss">

.opportunity-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
}

.opportunity-media {
  width: 100%;
  height: 120px;
  position: relative;
  background: var(--cBlue);
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opportunity-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 35px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
  border-bottom-right-radius: 10px;
}

.border-right {
  border-right: 1px solid gray;
}

.opportunity-details {
  padding: 20px;

  label {
    font-size: 0.8rem;
  }

  .v-btn {
    height: 40px !important;
    text-transform: none !important;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    background: #88dec7 !important;
    color: white !important;
    text-transform: none !important;
  }
}
</style>